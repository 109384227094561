.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease 0s;
}

.popup.popup_opened {
  opacity: 1;
  visibility: visible;
}

.popup_opened {
  display: block;
}
.popup__body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.popup__form {
  background-color: #fff;
  color: #000;
  width: 35vw;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.popup__form-content {
  margin: 25px auto;
  width: 30vw;
  position: relative;
}

.popup__title {
  font-size: 1.8em;
  font-weight: 400;
}
.popup__children-container {
  display: flex;
  flex-direction: column;
  padding: 0 15px 25px;
}
.popup__button-list {
  display: flex;
  max-width: 400px;
  gap: 15px;
  align-items: center;
}

.popup__close-button {
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../../images/PopUp/CloseIcon.svg);
  background-size: contain;
  border: none;
  cursor: pointer;
  position: absolute;
  top: -1.5vh;
  right: -1.8vw;
  width: 20px;
  min-height: 20px;
}

.popup__close-button:hover {
  opacity: 0.5;
}
