.functional-link {
  display: inline-block;
  padding: 0 7px;
  border-right: 1px solid #263fff;
}
.functional-link__link {
  border: none;
  background-color: inherit;
  text-decoration: underline;
  color: #263fff;
  cursor: pointer;
}
