.my-title {
  position: absolute;
  display: block;
  width: 300px;
  height: 100px;
  background: url(../../images/AuthPage/hello.svg) no-repeat;
  background-size: 80px;
  background-position: 10px center;
  background-color: #fff;
  box-shadow: 0 0 5px 5px #80808040;
  border-radius: 15px;
  opacity: 0;
  overflow: hidden;
  z-index: 100;
  transition: opacity 0.4s ease-in;
  padding: 20px;
  padding-left: 100px;
  font-size: large;
  font-weight: 600;
  user-select: none;
  text-align: center;
}

.my-title.active {
  opacity: 1;
}
