.line-page {
  display: flex;
  flex-direction: row-reverse;
  background-color: #e2e2e2;
  width: 100%;
  height: 100vh;
}
.line-page__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 100px;
  gap: 10px;
  align-content: center;
  width: 20vw;
  height: 100vh;
  background-color: #e2e2e2;
  box-shadow: 0 0 10px 5px #80808040;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.line-page__line-block {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 10px 5px #8080801d;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  gap: 15px;
}
.line-page__log-block {
  background-color: #fff;
  box-shadow: 0 0 10px 5px #8080801d;
  border-radius: 15px;
}
.line-page__chenge-login {
  background-color: #e2e2e2;
}
.line-page__time-out {
  background-color: #e2e2e2;
}
.line-page__chenge-status {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  padding: 10px 0;
  margin: 0 auto;
  width: 90%;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  text-align: center;
  background-color: #e2e2e2;
}
.line-page__chenge-status:hover {
  box-shadow: 0 0 10px 5px #8080805e;
}
.line-page__chenge-status span {
  content: none;
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 7px;
  background: url(../../images/LinePage/icon_X.svg);
  background-size: contain;
}
.line-page__log-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.line-page__chenge-log {
  color: #808080;
}
