.telephony {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px 5px #8080801d;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 10px;
}
.telephony.open {
  width: 800px;
}
.telephony__block-1 {
  display: none;
}
.telephony__block-1.open {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.telephony__block-1-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
}
.telephony__block-1-button span {
  content: none;
  display: block;
  width: 20px;
  height: 20px;
  background: url(../../images/Telephony/close.svg) no-repeat;
  background-position: center;
  background-size: 50%;
}
.telephony__block-2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.telephony__call-form {
  display: none;
}
.telephony__call-form.open {
  display: block;
  background-color: #e2e2e2;
  padding: 6px;
  border-radius: 15px;
}
.telephony__call-form-input {
  border-radius: 15px;
  width: 97%;
  padding: 4px;
  margin: 0 auto;
  border: none;
}
.telephony__call-button-list {
  display: none;
}
.telephony__call-button-list.active {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}
.telephony__call-button {
  display: inline-block;
  font-size: x-small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 10px 20px;
  margin: 0;
}
.telephony__call-button_last-position {
  grid-column: 1 / span 2;
}
.telephony__extension-number {
  display: none;
}
.telephony__extension-number.open {
  display: flex;
  justify-content: space-between;
  background-color: #e2e2e2;
  border-radius: 15px;
  padding: 5px;
}
.telephony__extension-number input {
  border: none;
  padding-left: 5px;
}
.telephony__extension-number span {
  content: none;
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 7px;
  margin-right: 5px;
  background: url(../../images/Telephony/arrow_right.svg) no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: #fc0;
  opacity: 0.6;
}
.telephony__log-block {
  display: none;
}
.telephony__log-block.open {
  display: block;
  background-color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
