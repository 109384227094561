.form-widget {
  display: flex;
  min-width: 400px;
  flex-direction: column;
  background-color: #e2e2e2;
  box-shadow: 0 0 10px 5px #8080801d;
}
.form-widget__timer-container {
  display: flex;
  align-items: center;
}
.form-widget__duration {
  margin: 20px;
}
.form-widget__who-applying-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
}
.form-widget__theme-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px 10px;
}
.form-widget__label {
  margin: 0;
  padding: 0;
}
.form-widget__label span {
  color: red;
}
.form-widget__input-datalist {
  display: inline-block;
  text-align: right;
}
.form-widget__theme-list {
  display: flex;
  margin: 0 20px;
  gap: 5px;
  flex-direction: column;
  align-items: flex-end;
}
.form-widget__text-area-container {
  margin: 10px 20px;
}
.form-widget__text-area {
  margin-top: 10px;
  width: 100%;
  min-height: 250px;
}
.form-widget__solution-text {
  font-size: small;
  margin: 0 20px;
}
.form-widget__solution {
  display: flex;
  align-items: center;
  margin: 0 20px;
  gap: 10px;
}
.form-widget__numder-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  gap: 10px;
}
.form-widget__numder-container p {
  display: inline-block;
  max-width: 40%;
  font-size: small;
}
.form-widget__numder-container span {
  font-size: small;
}
.form-widget__numder-container div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.form-widget__numder-container button {
  font-size: small;
}
.form-widget__id-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  gap: 10px;
}
.form-widget__id-container div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.form-widget__id-container button {
  font-size: small;
}
.form-widget__complete-button {
  background-color: #fc0;
  font-size: small;
  border-radius: 3px;
  margin-left: 20px;
  margin-top: 20px;
  padding: 5px 10px;
  display: flex;
  width: 80%;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.form-widget__checbox-img {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: url(../../images/FormWidjet/checkmark.svg) no-repeat;
  background-position: center;
  background-size: cover;
}
.form-widget__alt-img {
  color: #fc0;
  background-color: #968265;
  padding: 3px 7px;
  border-radius: 3px;
}
.form-widget__alt-img_position {
  margin-left: 20px;
}
