.theme-el {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 7px 20px;
  padding-right: 7px;
  background-color: rgb(0, 255, 100);
  border-radius: 15px;
}
.theme-el p {
  margin: 0;
  padding: 0;
}
.theme-el span {
  display: block;
  content: "";
  width: 20px;
  height: 20px;
  background: url(../../../images/LinePage/icon_X.svg) no-repeat;
  background-size: 12px;
  background-position: center;
  background-color: #fff;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}
.theme-el span:hover {
  box-shadow: 0 0 5px 5px #8080805e;
}

.form-widget__numder-container {
  display: flex;
  align-items: center;
  margin: 20px;
}
