.telephony-button-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.telephony-button-list__button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.telephony-button-list__button-container div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.telephony-button-list__button-container.open {
  flex-direction: row;
  justify-content: space-between;
}
.telephony-button-list__button-container.open div {
  flex-direction: row;
}
.telephony-button-list__button {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.telephony-button-list__button_mute {
  background: url(../../../images/Telephony/microphone.svg) no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: #e2e2e2;
}
.telephony-button-list__button_hold {
  background: url(../../../images/Telephony/pause.svg) no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: #e2e2e2;
}
.telephony-button-list__button_size {
  background: url(../../../images/Telephony/arrow_left.svg) no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: #e2e2e2;
}
.telephony-button-list__button_size.open {
  margin: 0;
  background: url(../../../images/Telephony/arrow_right.svg) no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: #e2e2e2;
}
.telephony-button-list__button_complete {
  background: url(../../../images/Telephony/phone_call.svg) no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: #fc0;
}
.telephony-button-list__button_complete:disabled {
  opacity: 0.5;
  cursor: auto;
}
.telephony-button-list__button_complete.open {
  width: 100%;
  background-size: 20px;
  background-position: 10px;
}
