.start-page {
  display: flex;
  flex-direction: column;
  background-color: #e2e2e2;
  width: 100%;
  height: auto;
}
.start-page__alert-container {
  display: flex;
  min-height: 300px;
  width: 40vw;
  margin-left: 10vw;
  margin-top: 20vh;
  background-color: #fff;
}
.start-page__lock-img {
  display: block;
  width: 60px;
  height: 75px;
  padding: 20px;
}
.start-page__alert-content {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
.start-page__alert-title {
  font-size: 1.3em;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.start-page__discription {
  font-size: 1.2em;
}
.start-page__discription span {
  font-weight: 500;
}
.start-page__err-msg {
  color: gray;
}
.start-page__link-list {
  display: flex;
  gap: 15px;
  margin-top: 40px;
}
.start-page_link {
  text-decoration: none;
  color: #000;
  padding: 7px 20px;
  background-color: #fc0;
  cursor: pointer;
}
.start-page__button {
  display: block;
  background-color: inherit;
  padding: 7px;
  width: 200px;
  border: 2px solid #8080806b;
  text-align: right;
  cursor: pointer;
}
.start-page__details-container {
  display: block;
  width: calc(40vw - 3px);
  margin-left: 10vw;
  padding: 20px 0;
  border-bottom: 2px solid gray;
  border-left: 2px solid gray;
  border-right: 2px solid gray;
  user-select: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease 0s;
}

.start-page__details-container.active {
  user-select: auto;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease 0s;
}

.start-page__details-text {
  color: gray;
  max-width: 500px;
  margin: 10px auto;
}
.start-page__details-text span {
  font-weight: 500;
}

.start-page__details-link-list {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 500px;
  margin: 40px auto 10px;
}
.start-page__details-link {
  text-decoration: none;
  padding: 7px 20px;
  border: 5px solid #fc0;
  color: gray;
  cursor: pointer;
}
.start-page__details-alert {
  color: gray;
}
