.auth-page {
  display: flex;
  flex-direction: row-reverse;
  background-color: #e2e2e2;
  width: 100%;
  height: 100vh;
}
.auth-page__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 100px;
  gap: 10px;
  align-content: center;
  width: 20vw;
  height: 100vh;
  background-color: #e2e2e2;
  box-shadow: 0 0 10px 5px #80808040;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.auth-page__auth-block {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 10px 5px #8080801d;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  gap: 15px;
}
.autth-page__log-block {
  background-color: #fff;
  box-shadow: 0 0 10px 5px #8080801d;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.auth-page__chenge-login {
  background-color: #e2e2e2;
}

.auth-page__chenge-status {
  font-size: 1.2em;
  padding: 10px 0;
  margin: 0 auto;
  width: 90%;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  text-align: center;
  background-color: #fc0;
}
.auth-page__chenge-status:hover {
  box-shadow: 0 0 10px 5px rgba(255, 204, 0, 0.35);
}

.autth-page__log-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-page__chenge-log {
  color: #808080;
}
