.login-container {
  display: flex;
  margin: 10px 20px;
  gap: 20px;
}
.login-container__img {
  display: block;
  width: 50px;
  border-radius: 10px;
  box-shadow: 0 0 10px 5px #8080801d;
}

.login-container__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.login-container__name {
  margin: 0;
  font-size: 1.2em;
}
.login-container__status {
  margin: 0;
  font-size: 0.9em;
  color: red;
}
.login-container__status.active {
  color: rgb(0, 180, 0);
}
.login-container__status.time-out {
  color: #fc0;
}
