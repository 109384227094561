.link-bar {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link-bar__container {
  display: block;
  padding: 10px;
}
.link-bar__button {
  text-decoration: none;
  text-align: center;
  color: #000;
  background-color: #f9f9f9;
  max-width: 250px;
  margin: 10px;
  border: 1px solid #000;
}
.link-bar__test-img {
  display: none;
}
.link-bar__test-img.open {
  display: block;
  width: 100%;
  height: 100%;
  background: url(../../images/LinkBar/demoImg.jpg) no-repeat;
  background-size: cover;
}
.link-bar__all-button-text {
  padding: 40px;
  text-align: center;
  font-size: x-large;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 65%;
  margin: 60px auto;
  background-color: #fff;
  border: 2px solid crimson;
  border-radius: 20px;
  box-shadow: 0 0 5px 5px #80808040;
}
.link-bar__all-button-text p {
  color: crimson;
  margin: 0;
}
