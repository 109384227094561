@import url(./vendor/normalize.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e2e2e2;
  overflow: hidden;
}

.button {
  display: block;
  border: none;
  font-size: 1.2em;
  padding: 10px 20px;
  margin: 0 auto;
  width: 90%;
  border-radius: 15px;
  cursor: pointer;
}
.button:hover {
  box-shadow: 0 0 10px 5px #8080805e;
}
.footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 30px;
  background-color: #e2e2e2;
}
.footer__text {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: small;
}
